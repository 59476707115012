<template>
  <RouterView />
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class MyStationRoute extends Vue {
  async getData () {
    this.$store.dispatch('getLocales');
    this.$store.dispatch('getStations');
    this.$store.dispatch('getGamesForStation');
  }

  created () {
    this.getData();
  }
}
</script>

<template>
  <div class="station-details">
    <div class="content">
      <ContentBlock :required="true" :loading="isFetchingData">
        <div slot="instructions">
          <h5>{{ $t("Station Settings") }}</h5>
          <p>
            {{ $t('Update the settings for this station.') }}
          </p>
        </div>
        <div class="station-basics">
          <UIInput class="station-name" :title="$t('Station Name')" required v-model="localStation.title"/>
          <div class="tablet"></div>
          <UISwitch
            class="switch"
            :title="$t('Use SpringboardVR Launcher')"
            :instructions="$t(`The Launcher gives players the ability to view and launch any titles available on this station.`)"
            v-model="localStation.launcherEnabled"
          />
          <UISelect :title="$t('Language / Locale')" :instructions="$t('Set the default language in the launcher.')" v-if="localStation.launcherEnabled" v-model="localStation.locale" required>
            <option
              v-for="locale in locales"
              :value="locale.key"
              :key="locale.key"
            >{{ locale.text }}</option>
          </UISelect>
          <UISelect
            :title="$t('Idle Mode')"
            :instructions="$t('When the headset goes idle after 5 minutes of inactivity, the desktop will appear or a video reel of content you have on your station will be displayed in a loop.')"
            v-model="localStation.idleMode"
            v-if="localStation.launcherEnabled"
          >
            <option :value="null">{{ $t('Off') }}</option>
            <option :value="'videos'">{{ $t('Videos') }}</option>
            <option :value="'desktop'">{{ $t('Desktop') }}</option>
          </UISelect>
          <div class>
            <UIFormItem
              v-if="station.idleMode === 'videos' && localStation.launcherEnabled"
              :title="$t('Default Idle Videos Volume')"
              :instructions="$t('The volume will default back to this whenever Idle Mode starts playing videos.')"
            >
              <VolumeSlider class="volumeslider" v-model="localStation.idleModeVolume"/>
            </UIFormItem>
          </div>
        </div>
      </ContentBlock>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import StationShowDetailsRoute from '@/location/stations/show/StationShowDetailsRoute';

@Component()
export default class MyStationShowDetailsRoute extends StationShowDetailsRoute {
  get locales () {
    return this.$store.getters['entities/Locale/all']();
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.station-basics {
  display: grid;
  grid-gap: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacingMd);
  }
}
</style>

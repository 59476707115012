<template>
  <div class="my-station container">
    <div>
      <div class="headerbar mw7">
        <div class="info">
          <h2 class="f3">{{ $t('Managing your Stations') }}</h2>
          <i18n
            tag="p"
            class="f5"
            path="To create a new station, go to the computer you will use to run VR titles, install the {springboardVRDesktopClient} and log into your account. A new station will be created on that computer and you will be able to edit its settings from this page."
          >
            <template #springboardVRDesktopClient>
              <a class="link-style" href="//support.springboardvr.com/hc/en-us/articles/360003063053">{{ $t('SpringboardVR desktop client') }}</a>
            </template>
          </i18n>
        </div>
      </div>

      <UIRowIndexLoader v-if="isLoading" />

      <div v-if="!isLoading && !noStations" class="animated fadeIn">
        <div class="stationsheadergrid f6">
          <div class="mobile">{{ $t('Station Name') }} / <br>{{ $t('Number Of Titles') }}</div>
          <div class="tablet">{{ $t('Station Name') }}</div>
          <div class="tablet">{{ $t('Number Of Titles') }}</div>
        </div>

        <div class="stationgrid">
          <div
            class="stationcard f-thead"
            v-for="station in stations"
            :key="station.id"
          >
            <div class="mobile">
              <h1 class="f-header nowrap truncate slate">{{ station.title }}</h1>
              <div>{{ station.gamesCount }} {{ $tc('title | titles', station.gamesCount) }}</div>
            </div>
            <h1 class="tablet title f-header nowrap dib truncate slate">{{ station.title }}</h1>
            <div
              class="numberoftitles tablet"
            >{{ station.gamesCount }} {{ $tc('title | titles', station.gamesCount) }}</div>
            <div class="edit">
              <UIButton
                tiny
                icon="edit"
                :to="{ name: 'my-station-show-details', params: { stationId: station.id } }"
              >{{ $t("edit") }}</UIButton>
            </div>
          </div>
        </div>
      </div>

      <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noStations">
        <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
        <i18n tag="p" path="You don't have stations yet. Install the {springboardVRDesktopClient} to create one on this computer.">
          <template #springboardVRDesktopClient>
            <a class="link-style" href="//support.springboardvr.com/hc/en-us/articles/360003063053">{{ $t('SpringboardVR desktop client') }}</a>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';

@Component({
  components: {
    UIRowIndexLoader,
    UIButton,
    UIIcon,
  },
})
export default class MyStationIndexRoute extends Vue {
  get isLoading () {
    return this.$store.state.myStations.isFetchingStations && this.stations.length === 0;
  }

  get noStations () {
    return !this.$store.state.myStations.isFetchingStations && this.stations.length === 0;
  }

  get stations () {
    return this.$store.getters['entities/Station/all']().sort((a, b) => a.title.localeCompare(b.title));
  }

  created () {
    this.$store.commit('setTitle', this.$t('My Stations'));
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.my-station {
  padding-top: var(--spacingMd);

  & .stationsheadergrid {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: var(--spacingSm);
    padding: 0 var(--spacingSm);
    margin-top: var(--spacingMd);
    position: relative;
    left: 0.2rem;
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      grid-gap: var(--spacingMd);
      padding: 0 var(--spacingAltMd);
      grid-template-columns: 1fr auto 5.5rem;
    }
  }

  & .stationgrid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .stationcard {
      background-color: var(--colorWhite);
      box-shadow: var(--shadowSm);
      border-radius: var(--radius);

      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: var(--spacingSm);

      @media (--tablet) {
        padding: var(--spacingAltSm) var(--spacingAltMd);
        grid-gap: var(--spacingMd);
        grid-template-columns: 1fr auto auto;

        & .edit {
          padding-left: var(--spacingMd);
        }
      }
    }
  }

  & .draghandle {
    cursor: grab;
    position: relative;
    text-align: center;
    fill: var(--colorFjord);

    & svg {
      width: 2.5rem;
    }
  }
}
</style>

<template>
  <div class="station-show">
    <SubHeader>
      <ul class="tabs" slot="tabs">
        <RouterLink tag="li" :to="{ name: 'my-station-show-details' }">{{ $t("Station Details") }}</RouterLink>
        <RouterLink tag="li" :to="{ name: 'my-station-show-content' }">{{ $t("Content") }}</RouterLink>
      </ul>
    </SubHeader>

    <div class="container" v-if="station">
      <div class="topactions">
        <BreadcrumbNavConnected class="breadcrumb"/>
        <UIButton tiny icon="trash" v-if="station.id" @click="deleteStation" class="delete-station">{{ $t("Delete Station") }}</UIButton>
      </div>
      <RouterView :station="station" :experiences="experiences" @edit="editStation" />
    </div>

    <BottomBar @save="storeStation" :saving="saving" :valid="valid" :backRouterLink="{ name: 'my-station-index' }"/>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import StationShowRoute from '@/location/stations/StationShowRoute';

@Component({
  props: {
    locationId: {
      required: false,
    },
  },
})
export default class MyStationShowRoute extends StationShowRoute {
  setBreadcrumbsForStation () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('My Stations'),
        routerLink: { name: 'my-station-index' },
      },
      {
        name: this.$t('Edit Station'),
      },
    ]);
  }

  async storeStation () {
    this.saving = true;
    this.$store.commit('setLoading', true);
    this.$store.commit('setLoadingOverlay', true);
    try {
      if (this.station.id) this.stationChanges.id = this.station.id;

      this.$store.commit(`stationManagement:${this.stationId}/setStationId`, this.stationId);
      await this.$store.dispatch(`stationManagement:${this.stationId}/persistState`);
      await this.$store.dispatch('storeStation', this.stationChanges);

      this.$store.commit('setFlash', {
        message: `${this.station.title} ${this.$t('was successfully saved.')}`,
        type: 'success',
      });
      this.$router.push({ name: 'my-station-index' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('There was an error saving this station, please try again.'),
      });
    } finally {
      this.$store.commit('setLoading', false);
      this.$store.commit('setLoadingOverlay', false);
      this.saving = false;
    }
  }

  deleteStation () {
    const buttons = [
      {
        name: this.$t('Cancel'),
        type: 'cancel',
      },
      {
        name: this.$t('Yes, Delete'),
        action: async () => {
          await this.$store.dispatch('deleteStation', { id: this.station.id });
          this.$router.push({ name: 'my-station-index' });
        },
      },
    ];
    this.$store.commit('setConfirm', {
      show: true,
      title: this.$t('Are you sure you want to delete this station?'),
      message: this.$t('Your station will be removed from the Operator Panel and its settings will be lost. You should uninstall the Desktop Client from your station\'s computer. You can install it again afterward if you want to create a new station for the same computer.'),
      icon: 'icon-trash',
      buttons,
    });
  }
}
</script>
